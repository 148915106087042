export default {
    "props": {
        "containerProps": {
            "elevation": 0,
        },
        "contentProps": {

        },
        "titleProps": {
            "variant": "caption",
            "component": "h2",
        },
        "titleLinkMoreProps": {
            "variant": "caption",
        },
        "titleLinkMoreTooltipProps": {
            "title": "",
            "placement": "top-end",
            "arrow": true,
        },
    },
    "sx": (theme) => ({
        "marginBottom": theme.spacing(2.6),
        [theme.breakpoints.up('sm')]: {
            "marginBottom": theme.spacing(6),
        },
        [theme.breakpoints.up('lg')]: {
            "marginBottom": theme.spacing(7),
        },
        "& .globalComponentLayout-titleContainer": {
            "display": "flex",
            "flexDirection": "row",
            "alignItems": "flex-end",
            "minHeight": 56,
            "paddingBottom": theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                "paddingBottom": theme.spacing(3),
            },
            
        },
        "& .globalComponentLayout-title": {
            "fontWeight": theme.typography?.layoutTitle?.fontWeightBold || theme.typography.fontWeightBold,
            "fontFamily": theme.typography?.layoutTitle?.fontFamily || 'initial',
            // "color": theme.palette.text.primary,
            "fontSize": theme.h6,
            [theme.breakpoints.up('sm')]: {
                "fontSize": theme.h5,
                "lineHeight": theme.h5LineH,
                // "color": theme.palette.text.primary,
            },
            "& span": {
                fontWeight: 400,
                fontSize: "16px",
                marginTop: "10px",
                marginLeft: "10px"
            }
        },
        "& .globalComponentLayout-titleLinkMore": {
            "fontWeight": theme.typography.fontWeightBold,
            "color": theme.palette.grey.grey2,
            "marginLeft": "auto",
            [theme.breakpoints.up('sm')]: {
                "fontSize": theme.bodyMedium,
                "lineHeight": theme.lineHeightBMedium,
                "fontWeight": "normal",
                "color": theme.palette.text.primary,
            },
        },
    }),
    "subthemes": {
        // Remove global space between components if we nest multiple global components
        "globalComponentLayout": {
            "props": {
                "containerProps": {
                    "elevation": 0,
                },
            },
            "sx": (theme) => ({
                "marginBottom": 0,
            }),
        },
    }
}
